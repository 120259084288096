import React from 'react'
import './Subscribe.scss'
import {Link} from "react-router-dom"

const Error = () => {
  return (
    <div className="container m-w">
    <div className="columns is-mobile">
        <div className="column is-full">
            <div className="card">
                <div className="card-content">
                    <div className="column has-text-centered">
                        <p className='text-lg bold'>Something went wrong!</p>
                        <Link to="/" className="button is-rounded btn-main mt-3">Go To Home </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Error