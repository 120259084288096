import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import CommonSlider from "../commonComponents/CommonSlider";
import BannerGame from "../commonComponents/BannerGame";
import _ from "lodash";
import rotate from "../assets/icons/rotate_device.png";
import "./LandscapePopUp.scss";
import close from "../assets/icons/popup_close_icon.png";
import { SendGuiDataEvents } from "../CommonScript";

function Game({ view_all, portrait_txt, rotate_txt, landscape_txt, similar_cat_txt, allGames }) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGame, setSelectedGame] = useState();
  const [remGames, setRemGames] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showLandscapeModal, setShowLandscapeModal] = useState(false);
  const modeRef = useRef(null);
  const { title, id } = useParams();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
  };
 
  useEffect(() => {
    let guiEvents={}
    guiEvents["page"]="game";
    guiEvents["event"]="open";
    SendGuiDataEvents(guiEvents);
    const handlePageShow = (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    };
    window.addEventListener("pageshow", handlePageShow);
    return () => window.removeEventListener("pageshow", handlePageShow);
  }, []);
  
  useEffect(() => { 
    const curGames = _.find(allGames, function (o) {
      return o?.contents?.[0]?.category == title;
    });
    if (allGames && curGames) {
      const game = curGames?.contents?.find((item, i) => item.id == id);
      const remGames = curGames?.contents?.filter((item, i) => item.id != id);
      if (game) {
        modeRef.current = game?.mode;
      }
      if (
        game.mode === "landscape" &&
        window.matchMedia("(orientation: portrait)").matches
      ) {
        setShowModal(true);
        setShowLandscapeModal(false)
      }
      if (
        game.mode === "portrait" &&
        window.matchMedia("(orientation: landscape)").matches
      ) {
        setShowLandscapeModal(true);
        setShowModal(false)
      }
      setSelectedGame(game);
      setRemGames(remGames);
      setIsLoading(false)
    } 
  }, [id,allGames]);

  const handleResize = () => {
    console.log("resize")
    const gameMode = modeRef?.current;
    if (window.matchMedia("(orientation: landscape)").matches && gameMode?.toUpperCase() === "PORTRAIT") {
      setShowModal(false)
      setShowLandscapeModal(true)
    }
    else if (window.matchMedia("(orientation: portrait)").matches && gameMode?.toUpperCase() === "LANDSCAPE") {
      setShowModal(true)
      setShowLandscapeModal(false)
    }
    else if (window.matchMedia("(orientation: portrait)").matches && gameMode?.toUpperCase() === "PORTRAIT") {
      setShowModal(false)
      setShowLandscapeModal(false)
    }
    else if (window.matchMedia("(orientation: landscape)").matches && gameMode?.toUpperCase() === "LANDSCAPE") {
      setShowModal(false)
      setShowLandscapeModal(false)
    }
    else {
      console.log("none of the above case")
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize])

  return (
    <>
      {isLoading  &&  <div className="loader-container">
        <div className="lds" ></div>
      </div>
      }
      {!isLoading && (
        <React.Fragment>
          {selectedGame && (
            <div>
              <BannerGame game={selectedGame} setIsLoading={setIsLoading} />
              <div className="container m-w mt-6">
                {allGames && remGames && (
                  <CommonSlider
                    settings={{
                      ...settings,
                      dots: false,
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      centerMode: true,
                      centerPadding: "10%",
                    }}
                    title={similar_cat_txt}
                    data={remGames}
                    view_btn_txt={view_all}
                    category={selectedGame.category}
                  />
                )}
              </div>
            </div>
          )}
          {showModal && (
            <div className="pop-bg container is-gapless pagebanner column p-r p-0">
              <div className="popup-modal">
                <img src={close} alt="close" className='model-close' onClick={() => {
                  setShowModal(false)
                }} />
                <img
                  src={rotate}
                  alt="rotate"
                  className="rotate-img"
                  width="90"
                />
                <div className="modal-content">
                  <h1 className="column p-r p-0  text-lg bold">
                    {rotate_txt}
                  </h1>
                  <h1 className="column p-r p-0 ">
                    {landscape_txt}
                  </h1>
                </div>
              </div>
            </div>
          )}
          {showLandscapeModal && (
            <div className="pop-bg container is-gapless pagebanner column p-r p-0">
              <div className="popup-modal2">
                <img src={close} alt="close" className='model-close' onClick={() => {
                  setShowLandscapeModal(false)
                }} />
                <img
                  src={rotate}
                  alt="rotate"
                  className="rotate-img"
                  width="90"
                />
                <div className="modal-content">
                  <h1 className="column p-r p-0  text-lg bold">
                    {rotate_txt}
                  </h1>
                  <h1 className="column p-r p-0 ">
                    {portrait_txt}
                  </h1>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </>
  );
}

export default Game;