import React,{useEffect} from 'react';
import './TermsAndConditions.scss';
import {termsAndConditionsText as text} from "../Text";
import { SendGuiDataEvents } from '../CommonScript';

const TermsAndConditions = () => {
    useEffect(() => {
      let guiEvents={}
      guiEvents["page"]="terms";
      guiEvents["event"]="open";
      SendGuiDataEvents(guiEvents);
    }, []);

    return (
        <div className="terms-conditions-container">
           <h1 className="text-2xl font-extrabold mb-4 text-white">
        Terms And Conditions
      </h1>
      {text.map((faq, index) => (
           <h3 key={faq.id} className='text-white text-lg text-left pb-3'>{faq.p}</h3>
          )
        )}
        </div>
    );
}

export default TermsAndConditions;
