import React,{useEffect} from 'react';
import { SendGuiDataEvents } from '../CommonScript';
import { useLanguage } from '../Context/LangContext';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import selected from "../assets/icons/language_select.png";
import deselect from "../assets/icons/language_deselect.png";
const Language = ({ langObj }) => {
  let history=useHistory();
  const { language, changeLanguage } = useLanguage(); 
  useEffect(() => {
    let guiEvents={}
    guiEvents["page"]="language";
    guiEvents["event"]="open";
    SendGuiDataEvents(guiEvents);
  }, []);
  const onLanguageSelect = (lang) => {
    reactLocalStorage.set('lang','en');
    changeLanguage(lang); 
    history.push("/home")
  };

  return (
    <div className="container m-w">
    <div className="columns is-mobile">
        <div className="column is-full">
            <div className="">
                <div className=" ">
                    <div className="display">
                       <div className="language-options">
            {langObj?.option?.map((option) => (
             <div
               key={option.id} // Use a unique key for each item
               className={`language-option text-lg bold ${
                 language === option.key ? 'selected' : ''
               }`} // Highlight the selected language
               onClick={() => onLanguageSelect(option.key)} // Change the language on click
             >
                <div>{language === option.key? <img src={selected} className='lang-icon' alt="selected"/>:
                <img src={deselect}  className="lang-icon" alt="deselect"/>
                }
                </div>
               <div className='lang-code'>{option.lang}</div> {/* Display the language name */}
             </div>
           ))}
         </div>
           </div>
          </div>
            </div>
        </div>
    </div>
   </div>
  )
};
  // return (
    // <div className="modal-bg">
    //   <div className="model-content text-white">
    //     <img
    //       src={close}
    //       alt="close"
    //       className="modal-close"
    //       style={{right:10,top:10,position:"absolute"}}
    //       onClick={() => setShowLangModal(false)}
    //     />
    //     <h1 className="text-center text-lg m-2 bold">{langObj?.title}</h1>
    //     <div className="language-options">
    //        {langObj?.option?.map((option) => (
    //         <div
    //           key={option.id} // Use a unique key for each item
    //           className={`language-option ${
    //             language === option.key ? 'selected' : ''
    //           }`} // Highlight the selected language
    //           onClick={() => onLanguageSelect(option.key)} // Change the language on click
    //         >
    //           {option.lang} {/* Display the language name */}
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  // );
// };

export default Language;
// 