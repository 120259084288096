import { SendGuiDataEvents } from "../CommonScript";

export const hitCallBackApi = (num, camp, rcid, setLoading, history) => {
    setLoading(true);

    // Send the request event
    let guiReqEvents = {
        page: 'home',
        event: 'callBackApi_request',
        rcid,
        trxID: num,
        camp
    };
    SendGuiDataEvents(guiReqEvents);

    const url = "https://camp-reports.bngrenew.com:5455/digitalservice/api/sendcallback";
    const headers = {
        'Content-Type': 'application/json'
    };

    // Set up the fetch request
    const fetchCallBack = fetch(url, {
        method: 'POST',
        body: JSON.stringify({ 'camp': camp, 'rcid': rcid, 'uid': num, "opco": "saf_tmk_kn_cg", ua: navigator.userAgent }),
        headers: headers
    }).then(res => {
        if (!res.ok) { 
            throw res;
        }
        return res.text();
    });

    // Set up the timeout
    const timeout = new Promise((_, reject) =>
        setTimeout(() => {
            reject(new Error("Request timed out"));
        }, 5000) // 5-second timeout
    );

    // Use Promise.race to race between fetch and timeout
    return Promise.race([fetchCallBack, timeout])
        .then((result) => {
            if (result === "ok") {
                console.log("callback api res::", result);

                let guiResEvents = {
                    page: 'home',
                    event: 'callBackApi_response',
                    rcid,
                    trxID: num,
                    camp
                };
                SendGuiDataEvents(guiResEvents);
                // setLoading(false);
            }
            return result;
        })
        .catch((error) => {
            console.log('error:::', error);
            setLoading(false);
            history.push("/error");
            throw error;
        });
};

