import React from 'react'
import './Modal.scss'
import low_bal from "../assets/icons/low_balance.png"
import error from "../assets/icons/technical_error.png"
import { useLanguage } from '../Context/LangContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const Modal = ({homeLogo,redirectUrl}) => {
    const {modalData}=useLanguage();
    const history=useHistory()
    return (
        <div className="container modal-container">
            <img src={homeLogo} alt="homelogo" className=''/>
            <div className='main-container'>
                <img src={modalData?.type==='error'?error:low_bal} alt="low balance" className=''/>
                <h1>{modalData?.title}</h1>
                <p>{modalData?.subTitle}</p>
                <h2>{modalData?.subTitle1}</h2>
               {modalData?.btnText && <button
                className="modalbtn margin text-white button-bg"
                onClick={() => 
                    {
                       window.open(redirectUrl,"_self")
                    }}
              >
               {modalData?.btnText}
              </button>
               }
            </div>
        </div>
    )
}

export default Modal;