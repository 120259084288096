import config from 'react-global-configuration'

config.set({
    ga_tag: "",
    ga_debug: false,
    base:"https://telecubesapis.bngrenew.com/apn",
    he_url:"http://he.cubegames.live/saf_ken_cg/",
    elastic_base_url:"https://serviceusagesv1.bngrenew.com/publish",
    domain : "btc",
    he : "/he",
    subscribe: '/subscribe',
    requestOTP: "/requestOTP",
    activate: "/activate",
    checkSub: "/checkSub",
    unsubscribe : '/unsubscribe',
    userPacks : '/userPacks',
    appConfig : '/config' , 
    headers: {
        'Reqfrom': 'web',
        'Username': 'web',
        'Servicefor':'saf_ke'
    }
})


export default config