import React from "react";
import Slider from "react-slick";
import "./Slider.scss";
import arrow from "../assets/icons/forward.png";
import { Link } from "react-router-dom";
import { logEvent } from '../Analytics/AnalyticsEvent';
import { screen } from '../Analytics/EventName';
import { reactLocalStorage } from "reactjs-localstorage";
import { useLocation } from "react-router-dom";

function BannerSlider(props) {
    const location=useLocation();
    const { settings, data, title,view_btn_txt} = props
    const slideritems = data?.map((item, i) => (     
        <Link to={`/game/${item.category}/${item.id}`} key={i}  >
            <div className="slider-card" onClick={() => {   
                if(location.pathname==="/" || location.pathname==="/home" ) 
                {
                    reactLocalStorage.set("category-clicked",title)
                }
            logEvent(
                {
                    screen: screen.homePage,
                    event : item.gameName + "_cardClick"
                },
                {
                    gameName : item.gameName,
                    mobile : reactLocalStorage.get('msisdn'),
                    date: new Date(),
                });
                
        }}>
            
                <div className="card-image">
                    <figure className="image is-1by1">
                        <img src={item.gameImage} alt={item.title} className="img-radius"
                         loading={i < 3 ? "eager" : "lazy"} />
                    </figure>
                </div>
                <div className="flex p-2" style={{flexDirection:'column'}}>
                      <div className="flex">
                        <div className="flex-left">
                        <h6 className="mb-0 text-white bold">{item.gameName}</h6>
                        </div>
                       </div>
                        <p className="text-left">{item.gamePlayed}</p>
                    </div>
                         <div className="flex-right bold">
                         </div>
                </div>
        </Link>
    ))
    return (
        <div className="columns is-multiline is-mobile common is-gapless">
            <div className="column is-full v-center display">
                <div>
                <span className="title is-6 mb-0  text-white line">{title}</span>
                </div>
                <div onClick={()=>
                {
                    if(location.pathname==="/" || location.pathname==="/home" ) 
                {
                    reactLocalStorage.set("category-clicked",title)
                }
                }}>
                 <Link className="is-small ml-auto text-white p-1 view-btn" to={{pathname:"/category",state:{categoryData:data,datacategory:data?.[0].category}}} >
                                {view_btn_txt} 
                    <img src={arrow} alt="arrow" className="ml-1" />
                </Link> 
                </div>             
            </div>
            <div className="column is-full" >
                <Slider {...settings}>
                    {slideritems}
                </Slider>
            </div>
        </div>
    )
}

export default BannerSlider