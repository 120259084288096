const termsAndConditionsText=
[
    {
        id:1,
        p:'The followings are the terms and conditions for use of CubeGames and its services (the "WebApp" ) and the CubeGames WebApp and its services (collectively the WebApp is referred to as the "Service"). The service are owned and operated by Telecubes Mobile Services FZC LLC ("CubeGames," or "us," or "we").'
    },
    {
        id:2,
        p:'By downloading or using the application, availing the services, continuing to access the services, or submitting any information through the services, and in consideration for the services CubeGames provides to you, YOU (the terms "you", “user” or "yours" includes the person or entity that is accessing the services as well as any third-parties accessing the services on your behalf) are stating that you agree to be bound by all of these terms and conditions governing the use of the services (the "Terms"). use of the services is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices, including the class action waiver, contained herein'
    }, 
]
export  {termsAndConditionsText}
