import React, { createContext, useContext, useState } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';


const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {

  const [language, setLanguage] = useState(reactLocalStorage.get('lang') || 'en');
  const [modalData,setModalData]=useState({});

  const changeLanguage = (lang) => {
    reactLocalStorage.set('lang', lang);
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, 
    changeLanguage ,
    modalData,setModalData}}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
